import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import success from "../../images/audio/success.svg";
import {changeFreeSubsModelStatus} from '../../helper/service'
import { useNavigate } from "react-router-dom";

const ConfirmationModal = (props) => {
  const brandColor = localStorage.getItem("subadminBrandColor") ?? "#12aeb4";
  const [isOpen, setIsOpen] = useState(true);
  const [classes, setClasses] = useState("justify-content-center");
  const navigate=useNavigate();

  useEffect(() => {
    if (props.type === "delete" || props.type === "logout" ) {
      setClasses("justify-content-end");
    }

    $(document).ready(function () {
      $(".multiPurposePopup").attr("id", "confirmationModal");
    });
  }, [props.type]);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    if(props.onClose)
    {
      props.onClose();
    }
    if(!props.freeSubsCheckModel){
      changeFreeSubsModelStatus().then(resp=>{
        console.log("free subs status change",resp)
      })
    }
    setIsOpen(false);
    if(props.paidSubsLink){
    window.location.href=props.paidSubsLink;
    }
    if(props.certificateLink){
      navigate(`/course/${props.courseId}/certificate`)
    }
  };

  return (
    <>
      <Modal
        className="multiPurposePopup"
        show={isOpen}
        onHide={closeModal}
        backdrop="static"
      >
        <Modal.Header>
          {(props.type === "info" && (
            <div
              className="w-30 h-30 d-flex rounded-3 p-1 mt-3 text-white"
              style={{ backgroundColor: `${brandColor}` }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-info-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
              </svg>
            </div>
          )) ||
            (props.type === "success" && (
              <div
                className="w-30 h-30 d-flex rounded-3 p-1 mt-3 text-white"
                style={{ backgroundColor: `${brandColor}` }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                </svg>
              </div>
            )) ||
            (props.type === "warning" && (
              <div
                className="w-30 h-30 d-flex border border-white rounded-5 p-1 mt-3"
                style={{
                  backgroundColor:`${brandColor}`,
                  color: "rgb(255 119 0)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-exclamation-triangle-fill"
                  viewBox="0 0 16 16"
                  style={{
                    margin:"1px 2px 2px"
                  }}
                >
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                </svg>
              </div>
            )) ||
            (props.type === "continue" && (
              <div
                className="w-30 h-30 d-flex rounded-5 p-1 mt-3 text-white"
                style={{ background: `${brandColor}`}}
              >
                <svg xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  fill="currentColor" 
                  class="bi bi-arrow-right-circle-fill" 
                  viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                </svg>
              </div>
            )) ||
            (props.type === "logout" && (
              <div
                className="w-30 h-30 d-flex border border-white rounded-5 p-1 mt-3"
                style={{
                  backgroundColor:`${brandColor}`,
                  color: "rgb(255 119 0)",
                }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill= "currentColor" 
                  class="bi bi-door-closed-fill"
                  viewBox="0 0 16 16"
                  style={{
                    margin:"1px 2px 2px"
                  }}                
                  >
                  <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z" />
                  <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0" />
                </svg>
              </div>
            ))}
          <div>
            <h4 className="title mt-3">{props.title}</h4>
            <p className="text-sm text-secondary">{props.message}</p>
          </div>
        </Modal.Header>
        <Modal.Body className={`d-flex bg-light rounded-3 ${classes}`}>
          {(props.type === "info" && (
            <button
              onClick={closeModal}
              type="button"
              className="btn text-white"
              style={{ background: `${brandColor}` }}
            >
              {props.btnText ? props.btnText : "OK"}
            </button>
          )) ||
            (props.type === "success" && (
              <button
                onClick={closeModal}
                type="button"
                className="btn text-white fs-6"
                style={{ background: `${brandColor}` }}
              >
                {props.btnText ? props.btnText : "OK"}
              </button>
            )) ||
            (props.type === "delete" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn mx-2 bg-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    borderColor: `${brandColor}`,
                    color: `${brandColor}`,
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    props.onConfirm();
                  }}
                  type="button"
                  className="btn btn-danger"
                >
                  {props.btnText ? props.btnText : "Delete"}
                </button>
              </>
            )) ||
            (props.type === "warning" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn mx-2 text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ backgroundColor: `${brandColor}` }}
                >
                  OK
                </button>
              </>
            ))||
            (props.type === "continue" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn mx-2 text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ backgroundColor: `${brandColor}` }}
                >
                  Continue
                </button>
              </>
            )) ||
            (props.type === "logout" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn text-black border border-1 mx-2 bg-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    props.onConfirm();
                  }}
                  type="button"
                  className="btn mx-2 text-white"
                  style={{ backgroundColor: `${brandColor}` }}
                >
                  {props.btnText ? props.btnText : "Logout"}
                </button>
              </>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationModal;