import React, { Component, useEffect, useState } from "react";
import Header from "../common/Header";
import Loader from "../Loader";
import { NavLink, useParams, useLocation, Link, useNavigate } from "react-router-dom";
import TopSearchResultComponent from "./TopSearchResultComponent";
import {
  getCourseModule,
  courseLessons,
  getCourseModulePurchaseCourse,
  userInfo,
  purchasecourseLessons,
  getCourseById,
  enrollCourse,
  unenrollCourse,
  courseSnapshot,
} from "../../helper/service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import SuccessPopup from "../Setting/SuccessPopup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import { commonUtil } from "../../helper/CommonUtils";


let brandColor = localStorage.getItem("subadminBrandColor");

const CourseModule = (props) => {
  const navigate=useNavigate();
  const [BrandColor, setBrandColor] = useState(brandColor);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [purchaseLessonCour, setpurchaseLessonCour] = useState(false);
  const [courseLesson, setCourseLesson] = useState([]);
  const [courseModule, setCourseModule] = useState([]);
  const [lessonId, setLessonId] = useState();
  const [totalLessons, setTotalLessons] = useState([]);
  const [totalStudents, setTotalStudents] = useState([]);
  const [state, setCourseState] = useState("");
  const { courseId } = useParams();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [hideCertificate, setHideCertificate] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [courseTotLesson, setCourseTotLesson] = useState(0);
  const [courseCompletedLesson, setCourseCompletedLesson] = useState(0);
  const [messageForContent, setMessageForContent] = useState("");
  const queryClient = useQueryClient();

  localStorage.setItem("couseIdForLesson", courseId);

  const partnerId = localStorage.getItem("subadminId");
  const memberId = localStorage.getItem("user_id");
  useEffect(() => {
    getCourseById(courseId)
      .then((res) => {
        if (res.result.length > 0) {
          setCourseState(res.result[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        setCourseState("");
      });
    cheeckForCourseCertificate();
  }, [courseId]);

  useEffect(() => {
    checkEnrollment();
  }, [state]);
  const cheeckForCourseCertificate = async () => {
    const data = await courseSnapshot(courseId);
    if (data && data?.result) {
      let totalLessons = 0;
      let completedLessons = 0;
      data.result.forEach(item => {
        totalLessons += item.length;
        completedLessons += item.filter(lesson => lesson?.lessonStatus === "completed").length;
      });
      setCourseTotLesson(totalLessons);
      setCourseCompletedLesson(completedLessons)
    }
  }
  const enrollCourseMutation = useMutation({
    mutationFn: () => enrollCourse(courseId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getEnrolledCourses"]);
      await queryClient.invalidateQueries(["getAllCourses"]);
      setIsEnrolled(true);
      setShowSuccessPopup(true);
      setMessageForContent(
        "You have been enrolled successfully and added to social wall for the course/challenge."
      );
    },
    onError: (error) => {
      console.log("Enrollment error", error);
      alert("Something went wrong! Failed to enroll in the course");
    },
  });

  const unenrollCourseMutation = useMutation({
    mutationFn: () => unenrollCourse(courseId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getEnrolledCourses"]);
      await queryClient.invalidateQueries(["getAllCourses"]);
      setIsEnrolled(false);
      setShowSuccessPopup(true);
      setMessageForContent(
        "You have been unenrolled successfully and removed from the social wall."
      );
    },
    onError: (error) => {
      console.log("Unenrollment error", error);
      alert("Something went wrong! Failed to unenroll from the course");
    },
  });

  const downloadCertificate = () => {
    navigate(`/course/${courseId}/certificate`)
  };

  const handleEnroll = () => {
    const confirm = window.confirm("Are you sure you want to enroll?");
    if (!confirm) return;
    enrollCourseMutation.mutate();
    setShowSuccessPopup(false);
  };

  const handleUnenroll = () => {
    const confirm = window.confirm("Are you sure you want to unenroll?");
    if (!confirm) return;
    unenrollCourseMutation.mutate();
    setShowSuccessPopup(false);
  };

  const checkEnrollment = () => {
    if (state?.enrollment === "Active" || state.enrollment=="Paused") {
      const enrolled = state?.students?.includes(
        localStorage.getItem("user_id") || ""
      );
      setIsEnrolled(enrolled);
    } else {
      setIsEnrolled(false);
    }
  };

  const getCourseModuleByCourseId = () => {
    let subsId = localStorage.getItem("subscriptionId");
    let data = {
      courseId: courseId,
      partnerId: partnerId,
      memberAccessLevel: subsId,
      memberId: memberId,
    };
    getCourseModule(data)
      .then((courseModData) => {
        let sortedMod = courseModData.result.sort(
          (mod1, mod2) => mod1.courseModuleOrder - mod2.courseModuleOrder
        );
        setCourseModule(sortedMod);
      })
      .catch((err) => {
        console.log(err, "courseMod error");
      });
  };
  const getCourseModuleOfPurchaseCourse = () => {
    let data = {
      courseId: courseId,
      partnerId: partnerId,
      //  memberAccessLevel: state.memberAccessLevel,
      memberId: memberId,
    };
    getCourseModulePurchaseCourse(data)
      .then((courseModData) => {
        let sortedMod = courseModData.result.sort(
          (mod1, mod2) => mod1.courseModuleOrder - mod2.courseModuleOrder
        );
        setCourseModule(sortedMod);
      })
      .catch((err) => {
        console.log(err, "courseMod error");
      });
  };
  const getCourseLesson = async () => {
    await courseLessons(courseId).then((data) => {
      setCourseLesson(data.result);
      var currentDate = new Date();
      const courseLessons = data.result.filter(
        (lesson) =>
          (lesson.moduleId === undefined ||
            lesson.moduleId === lesson.courseId._id) &&
          lesson.hideForMember === false &&
          (lesson.schedule == null ||
            lesson.schedule == "" ||
            currentDate > new Date(lesson.schedule))
      );
      setTotalLessons(courseLessons.length);
      setTotalStudents(data.result[0]?.courseId?.students.length);
      setLessonId(courseId);
    });
  };
  const getPurchaseCourseLesson = async () => {
    await purchasecourseLessons(courseId).then((data) => {
      setCourseLesson(data.result);
      setpurchaseLessonCour(true);
      let currentDate = new Date();
      const courseLessons = data.result.filter(
        (lesson) =>
          lesson.moduleId == undefined ||
          lesson.moduleId == lesson.courseId._id ||
          currentDate > new Date(lesson.schedule)
      );
      setTotalLessons(courseLessons.length);
      setTotalStudents(data.result[0]?.courseId?.students.length);
      setLessonId(courseId);
    });
  };
  useEffect(() => {
    const userId = localStorage.getItem("user_id");

    userInfo(userId).then((data) => {
      let user = data.result.validUser;
      if (user.subscriptionId.length > 0) {
        getCourseModuleByCourseId();
        getCourseLesson();
      } else {
        getCourseModuleOfPurchaseCourse();
        getPurchaseCourseLesson();
      }
    });

    checkEnrollment();
  }, [state]);

  const handlePurchase = () => {
    let url = `/coursecheckout/${courseId}`;
    commonUtil.redirect(url);
  };

  return (
    <div className="wrapper">
      <Header />
      {loader ? <Loader /> : <></>}
      {showSuccessPopup ? <SuccessPopup message={messageForContent} /> : <></>}

      <div className="content-wrapper admin-courses module-courses">
        <section className>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-between align-items-center">
                  <h2 className="title">
                    <NavLink to="/courses">Return to Courses</NavLink>
                  </h2>
                  <div>
                    {(courseCompletedLesson === courseTotLesson && courseCompletedLesson !== 0) &&
                      < Button
                        className="purchase-button"
                        style={{
                          backgroundColor: `${BrandColor !== null ? BrandColor : ""
                            }`,
                          minWidth: "200px",
                          marginRight: '1em'
                        }}
                        onClick={downloadCertificate}
                      >
                        <span>Download Certificate</span>
                      </Button>
                    }
                    {isEnrolled && !state?.acceptIndPayment ? (
                      <Button
                        className="purchase-button"
                        style={{
                          backgroundColor: `${BrandColor !== null ? BrandColor : ""
                            }`,
                          minWidth: "150px",
                        }}
                        onClick={handleUnenroll}
                      >
                        <span>Unenroll</span>
                      </Button>
                    ) : isEnrolled && state?.acceptIndPayment ? (
                      <></>
                    ) : state?.acceptIndPayment ? (
                      <Button
                        className="purchase-button"
                        style={{
                          backgroundColor: `${BrandColor !== null ? BrandColor : ""
                            }`,
                          minWidth: "150px",
                        }}
                        onClick={handlePurchase}
                      >
                        <span>Purchase at ${state?.amount}</span>
                      </Button>
                    ) : (
                      <Button
                        className="purchase-button"
                        style={{
                          backgroundColor: `${BrandColor !== null ? BrandColor : ""
                            }`,
                          minWidth: "150px",
                        }}
                        onClick={handleEnroll}
                      >
                        <span>Enroll</span>
                      </Button>
                    )}
                  </div>
                </div>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    fontWeight: "normal",
                  }}
                >
                  Course Title: {state?.title}
                </h3>
                <div className="col-lg-4 col-md-4 col-sm-6 mx-auto">
                  <img
                    src={state?.courseImage}
                    className="rounded"
                    style={{ width: "100%", height: "16em", objectFit: "contain" }}
                    alt="module img"
                  />
                </div>
                <div
                  className="mt-3 w-100 description-content text"
                  dangerouslySetInnerHTML={{ __html: state?.description }}
                ></div>

                <h1>Modules</h1>
                <div
                  style={{ marginTop: "30px" }}
                  className="d-flex align-items-center flex-wrap"
                >
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      backgroundColor: "#e6edf5",
                      borderRadius: "23px",
                      fontWeight: "400",
                      fontSize: "18px",
                      color: "#0a1629",
                      padding: "10px 30px",
                      lineHeight: "27px",
                    }}
                    className="course-info"
                  >
                    {totalLessons > 0 ? totalLessons : 0} Lessons |{" "}
                    {totalStudents > 0 ? totalStudents : 0} Students
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          (state?.enrollment === "Active" || isEnrolled) && (
            <Swiper
              spaceBetween={25}
              slidesPerView={1}
              navigation={true}
              modules={[Navigation, Mousewheel]}
              breakpoints={{
                400: {
                  width: 400,
                  slidesPerView: 2,
                },
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
              }}
            >
              {courseModule.map((item, index) =>
                !item.hiddenForMember ? (
                  <SwiperSlide
                    key={index}
                    className="discover-slider courses-slider owl-carousel owl-theme highlight-card"
                    style={{
                      marginLeft: "1em",
                      border: `3px solid ${hoveredIndex === index ? BrandColor : "#ebebeb"
                        }`,
                      boxShadow:
                        hoveredIndex === index
                          ? `2px 2px 10px ${BrandColor}`
                          : "5px 5px 20px rgba(60, 118, 119, 0.2784313725)",
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <div>
                      {isEnrolled ? (
                        <Link to={`/course-module/module-details/${item._id}`}>
                          <div>
                            <img
                              src={item.image}
                              className="rounded"
                              style={{ width: "100%", height: "12em" }}
                              alt="module img"
                            />
                          </div>
                          <h5
                            className="slide-title"
                            style={{ color: BrandColor }}
                          >
                            {item.title}
                          </h5>
                        </Link>
                      ) : (
                        <div
                          onClick={() => {
                            alert(
                              "You can't access modules until you enroll in the course."
                            );
                          }}
                        >
                          <img
                            src={item.image}
                            className="rounded"
                            style={{ width: "100%", height: "12em" }}
                            alt="module img"
                          />
                          <h5
                            className="slide-title"
                            style={{ color: BrandColor }}
                          >
                            {item.title}
                          </h5>
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ) : null
              )}
            </Swiper>
          )
        }
        {/* <TopSearchResultComponent /> */}
        {
          isEnrolled && totalLessons > 0 && (
            <TopSearchResultComponent
              isEnrolled={isEnrolled}
              details={true}
              courseLesson={courseLesson}
              lessonId1={courseId}
              purchaseLessonCour={purchaseLessonCour}
            />
          )
        }
      </div >

    </div >
  );
};

export default CourseModule;
