import axios from "axios";
import { commonUtil } from "../helper/CommonUtils";

const BASEURL = commonUtil.BASEURL();

const getConfig = () => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return configData;
};

let validateCoupon = async (couponCode,type) => {
  const userId = localStorage.getItem("user_id");
  const res = await axios.post(BASEURL + "payment/coupons/validate", {
    couponCode,
    userId,
    type,
  });

  if (res.status !== 200) {
    throw new Error(
      res.data?.result.message ||
        "Failed to validate coupon, please try different coupon code"
    );
  }

  return res.data;
};

let validateAndApplyCoupon = async (data) => {
  const res = await axios.post(BASEURL + "payment/coupons/validateandapply",data);
// removing data from here and sending directly
  if (res.status !== 200) {
    throw new Error(
      res.data?.result.message ||
        "Failed to validate coupon, please try different coupon code"
    );
  }

  return res.data;
};

export { validateCoupon, validateAndApplyCoupon };
