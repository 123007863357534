import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { unsubscribeEmailMember, userInfo } from "../../helper/service";
import Header from '../common/Header';
import ConfirmationModal from '../Setting/ConfirmationModal';


const UnsubscribeVerification = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const queryString = location.search;
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [showModel, setShowModel] = useState(false);

  const urlParams = new URLSearchParams(queryString);

  const unsubscribeEmailId = urlParams.get('unsubscribeEmailId');
  const unsubscribeMemberId = urlParams.get('unsubscribeMemberId');
  const unsubscribeMemberEmail = urlParams.get('unsubscribeMemberEmail')

  const handleUnsubscribeEmail = async () => {

    const data = {
      emailId: unsubscribeEmailId,
      memberEmail: unsubscribeMemberEmail,
      memberId: unsubscribeMemberId,
    }
    await unsubscribeEmailMember(data).then((res) => {
      if (res.message == "Member successfuuly unsubscribe from email getting.") {
        setType('success')
        setTitle('Unsubscribe Email')
        setMessage('You are successfully unsubscribe from getting email.')

      }
      else if (res.message = "Member not unsubscribe from getting email.") {
        setType('warning')
        setTitle('Something went wrong')
        setMessage('Please try again if you want to unsubscribe email.')
      }
      setShowModel(true)
    }).catch(err => {
      console.warn("unsubscribe email error ", err)
    })
  }

  return (
    <>
      <Header />
      {showModel && <ConfirmationModal
        type={type}
        title={title}
        message={message}
        onClose={() => navigate('/dashboard')}
      // paidSubsLink={paidSubsLink}
      />
      }
      <div className="content-wrapper admin-social-wall">
        <div className="d-flex justify-content-center flex-column align-items-center" style={{ height: '20rem' }}>

          <p style={{ fontWeight: "400", fontSize: "18px" }}>Are you sure you want to unsubscribe from getting furthur emails?</p>
          <div className='d-flex gap-4'>
            <button
              onClick={handleUnsubscribeEmail}
              className="submit-btn px-3 py-2 invoice-btn"
              style={{ width: '7rem' }}
            >
              Confirm
            </button>
            <button
              onClick={() => navigate('/dashboard')}
              className="submit-btn px-3 py-2 invoice-btn"
              style={{ width: '7rem' }}
            >
              Cancel
            </button>
          </div>


        </div>
      </div>
    </>
  )
}

export default UnsubscribeVerification