import React, { useState, useEffect } from "react";
import home from "../../images/enduser/home.svg";
import userIcon from "../../images/enduser/user.svg";
import chat from "../../images/enduser/chat (9)-1.svg";
import document from "../../images/enduser/document.svg";
import courses from "../../images/enduser/courses.svg";
import challenges from "../../images/enduser/challenges.svg";
import events from "../../images/enduser/events.svg";
import { NavLink, Navigate } from "react-router-dom";
import supportImageMobile from "../../images/sidebar/support-mobile.png";
import Logout from "../../images/sidebar/logout.svg";
import { userInfo, userPurcahseCourse } from "../../helper/service";
import content from "../../images/enduser/content-lib.svg";
import { Modal } from "react-bootstrap";
import close from "../../images/enduser/close-button.svg";
import { useAuth } from "../../contexts/auth";
import ConfirmationModal from "../Setting/ConfirmationModal";

const brandColor = localStorage.getItem("subadminBrandColor");

export default function Sidebar() {
  const [is_redirect, setRedirect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [partnerEmail, setPartnerEmail] = useState("");
  const { user, setUser, logOutAction } = useAuth();
  const [modalType, setWarningModalType] = useState("");
  const [modalTitle, setWarningModalTitle] = useState("");
  const [modalMessage, setWarningModalMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);

  useEffect(() => {
    if (user !=null && user !=undefined) {
      if (user?.status != "Active") {
        alert("Your status is Not Active please contact your instructor");
        setRedirect(true);
      }
    } else {
      const userId = localStorage.getItem("user_id");
      userInfo(userId).then((data) => {
        console.warn("new user: ", data.result.validUser);
        setUser(data.result.validUser);
        if (data.result.validUser.status != "Active" && data.result.validUser.status !="Invited") {
          alert("Your status is Not Active please contact your instructor");
          setRedirect(true);
        }

        var imgs = "";
        if (data.result.validUser.image) {
          imgs = data.result.validUser.image;
        } else {
          imgs = localStorage.getItem("subadminImage");
        }

        setPartnerEmail(
          data.result.validUser?.subadminsId[0]?.["subadminId"]["email"]
        );
      });
    }
  }, []);

  const logout = () => {
    logOutAction();
    setRedirect(true);
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const BrandColor = localStorage.getItem("subadminBrandColor");
  const currentURL = window.location.pathname;

  if (is_redirect) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className="sidebar">
        {showWarningModal && (
          <ConfirmationModal
            type={modalType}
            title={modalTitle}
            message={modalMessage}
            onClose={() => {
              setWarningModal(false);
              setWarningModalMessage("");
              setWarningModalType("");
              setWarningModalTitle("");
            }}
            onConfirm={() => {
              setWarningModal(false);
              setWarningModalMessage("");
              setWarningModalType("");
              setWarningModalTitle("");
              if (modalType === "logout") {
                logout();
              }
            }}
          />
        )}

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink
                className={
                  currentURL === "dashboard" ? "nav-link active" : "nav-link"
                }
                to="/dashboard"
              >
                <img
                  src={home}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Home</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={
                  currentURL === "profile" ? "nav-link active" : "nav-link"
                }
                to="/account-details"
              >
                <img
                  src={userIcon}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Profile</p>
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink to="/content-library" className="nav-link">
                <img
                  src={content}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Content Library</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/courses" className="nav-link">
                <img
                  src={courses}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Courses</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/challenges-library" className="nav-link">
                <img
                  src={challenges}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Challenges</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/events" className="nav-link">
                <img
                  src={events}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Events</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/social-wall" className="nav-link">
                <img
                  src={chat}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Social Wall</p>
              </NavLink>
            </li>

            {/* <li className="nav-item">
                  <NavLink  to="/account-details" className="nav-link">
                    <img src={chat} className="nav-icon img-fluid" alt="sidebar-icon" />
                    <p>Memberships</p>
                  </NavLink>
                </li>    */}

            {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    <img src={heart} className="nav-icon img-fluid" alt="sidebar-icon" />
                    <p>Favourites</p>
                  </a>  
                </li> */}

            {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    <img src={folder} className="nav-icon img-fluid" alt="sidebar-icon" />
                    <p>
                      Info Portal
                      <i className="right fa fa-angle-right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Info Portal</p>
                      </a>
                    </li>
                  </ul>
                </li> */}

            <li className="nav-item mt-2">
              {/* <NavLink to="help.wisdome.com.au" className="nav-link border-0 shadow-none">\ */}
              <a onClick={openModal} className="nav-link border-0 shadow-none">
                <img
                  src={supportImageMobile}
                  className="support nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p className="support-img">
                  {/* <Image src={supportImage} width="100%"></Image> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="auto"
                    viewBox="0 0 255 282.984"
                  >
                    <g
                      id="Group_69360"
                      data-name="Group 69360"
                      transform="translate(-47.02 -1060.195)"
                    >
                      <rect
                        id="elm_card_lightblue"
                        data-name="elm/card/lightblue"
                        width="255"
                        height="224"
                        rx="24"
                        transform="translate(47.02 1119.18)"
                        fill="#ffffff"
                        opacity="0.101"
                      />
                      <g
                        id="Group_68165"
                        data-name="Group 68165"
                        transform="translate(110.523 1060.195)"
                      >
                        <path
                          id="Path_11"
                          data-name="Path 11"
                          d="M321.014,282.542c-.5,17.445-1.557,54.5-1.71,57.675a63.341,63.341,0,0,0,11.281-2.849c-2.515-12.976-6.737-25.572-8.055-38.749C321.672,293.043,321.642,287.743,321.014,282.542Z"
                          transform="translate(-206.132 -173.485)"
                          fill="#f2f2f2"
                        />
                        <path
                          id="Path_12"
                          data-name="Path 12"
                          d="M322.2,268.68s-.1,3.537-.254,8.979c.628,5.2.657,10.5,1.516,16.077,1.318,13.177,5.54,25.773,8.055,38.749a91.388,91.388,0,0,0,12.107-5.713C336.2,300.657,332.591,282.723,322.2,268.68Z"
                          transform="translate(-207.062 -168.602)"
                          fill="#fff"
                        />
                        <g
                          id="Group_10"
                          data-name="Group 10"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Path_13"
                            data-name="Path 13"
                            d="M168.059,334.243c-.227-.039-.453-.084-.674-.123l-.233-.045-1.464-.291a57.9,57.9,0,0,1-17.982-6.853c-.965-.531-1.969-1.088-3.038-1.691,7.4-27.413,11-46.23,21.382-60.967C166.05,264.272,167.858,329.864,168.059,334.243Z"
                            transform="translate(-144.618 -167.05)"
                            fill="#fff"
                          />
                          <path
                            id="Path_14"
                            data-name="Path 14"
                            d="M224.87,219.279c5.88-3.184,28.139-4.947,30.2,0s.013,11.255-2.031,12.749-13.071,8.251-13.071,8.251-12.617-3.769-14.155-9.413S223.426,221.3,224.87,219.279Z"
                            transform="translate(-172.607 -150.115)"
                            fill="#232566"
                          />
                          <g
                            id="Group_3"
                            data-name="Group 3"
                            transform="translate(14.757 53.811)"
                          >
                            <path
                              id="Path_15"
                              data-name="Path 15"
                              d="M272.134,333.845a4.006,4.006,0,0,1-1.109,1.454,34.967,34.967,0,0,1-11.086,7.011c-12.368,5.045-27.763,4.68-40.887,5.33a102.861,102.861,0,0,1-22.81-1.275,88.416,88.416,0,0,1-16.955-4.653,90.968,90.968,0,0,1-9.885-4.5,4.443,4.443,0,0,1-1.919-2.895,6.853,6.853,0,0,1,.724-4.089c5.995-15.574,5.258-9.7,4.428-27.409q-.647-13.831-1.328-28.154c-.258-5.47-1.141-14.764,1.262-20.029,1.792-3.937,15.713-15.893,27.563-20.64a33.287,33.287,0,0,1,11.564-2.742c2.828-.266,5.616-.411,8.311-.418.744-.007,1.507.019,2.27.073.936.06,1.879.159,2.835.292,1.48.2,2.967.5,4.475.85a64.342,64.342,0,0,1,9.546,3.167c13.941,5.769,26.535,15.733,28.619,20.128a17.631,17.631,0,0,1,.518,4.966c.172,5.776-.119,15.84-.631,27.823-.358,8.2-.843,17.293-1.347,26.468-.757,13.708,1.408,4.865,5.6,17.007A3.442,3.442,0,0,1,272.134,333.845Z"
                              transform="translate(-167.373 -209.083)"
                              fill="#fff"
                            />
                            <path
                              id="Path_16"
                              data-name="Path 16"
                              d="M222.278,230.908l-3.233,10.2s-21.25,19.577-23.175,31.985c-1.507,9.719-.245,48.195.372,74.171a88.418,88.418,0,0,1-16.955-4.653,90.974,90.974,0,0,1-9.885-4.5,4.443,4.443,0,0,1-1.919-2.895,6.853,6.853,0,0,1,.724-4.089c5.995-15.574,5.258-10.6,4.428-28.312q-.647-13.831-1.328-28.154c-.258-5.47-1.141-14.764,1.262-20.029,1.792-3.937,15.713-15.893,27.563-20.64a33.287,33.287,0,0,1,11.564-2.742c2.828-.266,5.616-.411,8.311-.418C220.751,230.828,221.514,230.855,222.278,230.908Z"
                              transform="translate(-167.373 -209.083)"
                              fill="#dce7e8"
                            />
                            <path
                              id="Path_17"
                              data-name="Path 17"
                              d="M254.791,220.975a55.636,55.636,0,0,1-14.28,12.633c-6.844-4.089-12.281-10.23-14.851-13.45.451-.133.883-.252,1.315-.358l-.3-22.18,26.641-.365.266,19.318.053,4.03C254.021,220.722,254.407,220.842,254.791,220.975Z"
                              transform="translate(-187.904 -197.255)"
                              fill="#fbc"
                            />
                            <path
                              id="Path_18"
                              data-name="Path 18"
                              d="M253.5,210.309l-.179-13.054-26.641.365.3,22.18q-.648.159-1.315.358a66.527,66.527,0,0,0,8.164,8.54C240.552,222.713,246.37,215.633,253.5,210.309Z"
                              transform="translate(-187.904 -197.255)"
                              fill="#e2a0b8"
                            />
                          </g>
                          <g
                            id="Group_7"
                            data-name="Group 7"
                            transform="translate(39.388)"
                          >
                            <g
                              id="Group_4"
                              data-name="Group 4"
                              transform="translate(0 37.97)"
                            >
                              <path
                                id="Path_19"
                                data-name="Path 19"
                                d="M211.21,173.734c-5.3-.157-6.2,4.455-5.685,6.989s3.737,7.816,7.567,4.618C213.431,174.685,211.21,173.734,211.21,173.734Z"
                                transform="translate(-205.397 -173.127)"
                                fill="#e2a0b8"
                              />
                              <path
                                id="Path_20"
                                data-name="Path 20"
                                d="M280.773,172.813c5.292-.3,6.323,4.285,5.873,6.833s-3.523,7.915-7.439,4.821C278.579,173.825,280.773,172.813,280.773,172.813Z"
                                transform="translate(-231.356 -172.799)"
                                fill="#fbc"
                              />
                            </g>
                            <path
                              id="Path_21"
                              data-name="Path 21"
                              d="M258.393,158.741c.006,15.587-7.294,30.6-22.293,30.8a19.6,19.6,0,0,1-5.292-.617c-11.53-2.9-17.5-15.944-17.834-29.57-.308-12.708,2.2-22.051,10-26.478,3.181-1.8,7.226-2.794,12.309-2.859C252.913,129.773,258.386,143.929,258.393,158.741Z"
                              transform="translate(-208.057 -119.757)"
                              fill="#fbc"
                            />
                            <path
                              id="Path_22"
                              data-name="Path 22"
                              d="M257.378,145.9c-.162,2.179-.8,5.018-1.029,7.626a13.251,13.251,0,0,0,.105,3.793l-2.973-11.41s-2.824-1.433-3.437-3.452c-1.275-4.107-2.016-6.407-2.045-6.4-9.123,5.34-29.615.891-29.615.891s-.718,4.688-1.294,6.979a5.68,5.68,0,0,1-3.541,3.737l-2.134,10.263s-3.61-17.854-3.575-24.218c.018-2.473.873-4.76,3.492-5.516a.172.172,0,0,1-.034-.056c-1.11-2.031.635-4.475,2.443-5.945,6.7-5.465,15.614-7.554,24.262-7.951a18.592,18.592,0,0,1,8.5,1.094c2.626,1.188,4.812,3.777,4.715,6.652,2.893-.021,5.385,2.393,6.343,5.141C259.449,132.515,257.787,140.245,257.378,145.9Z"
                              transform="translate(-206.257 -114.182)"
                              fill="#686868"
                            />
                            <g
                              id="Group_5"
                              data-name="Group 5"
                              transform="translate(16.514 43.267)"
                            >
                              <path
                                id="Path_23"
                                data-name="Path 23"
                                d="M233.974,183.061c.039,1.139-.62,2.053-1.47,2.042s-1.571-.944-1.61-2.084.62-2.054,1.47-2.042S233.935,181.922,233.974,183.061Z"
                                transform="translate(-230.891 -180.977)"
                                fill="#313384"
                              />
                            </g>
                            <g
                              id="Group_6"
                              data-name="Group 6"
                              transform="translate(34.478 42.812)"
                            >
                              <path
                                id="Path_24"
                                data-name="Path 24"
                                d="M261.682,182.607c-.144,1.132-.94,1.929-1.777,1.781a1.969,1.969,0,0,1-1.257-2.314c.144-1.131.94-1.928,1.777-1.781A1.969,1.969,0,0,1,261.682,182.607Z"
                                transform="translate(-258.624 -180.275)"
                                fill="#313384"
                              />
                            </g>
                            <path
                              id="Path_25"
                              data-name="Path 25"
                              d="M226.208,170.7c1.872-1.192,3.9-2.2,6.188-1.991,1.62.145.517-1.2-.637-1.22a8.147,8.147,0,0,0-5.52,2.068c-.319.288-.634.757-.388,1.109a.234.234,0,0,0,.142.1A.311.311,0,0,0,226.208,170.7Z"
                              transform="translate(-212.571 -132.96)"
                              fill="#313384"
                            />
                            <path
                              id="Path_26"
                              data-name="Path 26"
                              d="M262.84,168.72c-2.06-.825-4.238-1.437-6.449-.812-1.566.442-.73-1.086.4-1.317A8.147,8.147,0,0,1,262.6,167.6c.367.223.763.626.586,1.018a.236.236,0,0,1-.12.13A.314.314,0,0,1,262.84,168.72Z"
                              transform="translate(-223.056 -132.586)"
                              fill="#313384"
                            />
                          </g>
                          <path
                            id="Path_27"
                            data-name="Path 27"
                            d="M247.556,194.644a3.552,3.552,0,0,0,2.77-.99c.632-.941-.134-2.879-.387-3.845q-.6-2.3-1.419-4.532a2.419,2.419,0,0,0-.413-.8.846.846,0,0,0-.8-.3,1.134,1.134,0,0,0-.607.524c-.639.953-.582,2.357-.716,3.469q-.257,2.116-.448,4.24A1.849,1.849,0,0,0,247.556,194.644Z"
                            transform="translate(-180.141 -138.832)"
                            fill="#e2a0b8"
                          />
                          <g
                            id="Group_8"
                            data-name="Group 8"
                            transform="translate(49.252 69.166)"
                          >
                            <path
                              id="Path_28"
                              data-name="Path 28"
                              d="M237.39,235.711l-5.364,13.3S221.24,235.4,220.754,230.42c-.863-8.843,2.881-9.46,2.881-9.46a8.47,8.47,0,0,0,2.064,5.961,40.806,40.806,0,0,0,9.48,7.661Z"
                              transform="translate(-220.625 -220.96)"
                              fill={BrandColor}
                            />
                            <path
                              id="Path_29"
                              data-name="Path 29"
                              d="M247.089,249.979l-2.755-1.281,1.407-4.966Z"
                              transform="translate(-228.976 -228.981)"
                              fill={BrandColor}
                            />
                            <path
                              id="Path_30"
                              data-name="Path 30"
                              d="M251.342,248.7l-2.755,1.281,1.354-6.247Z"
                              transform="translate(-230.474 -228.981)"
                              fill={BrandColor}
                            />
                          </g>
                          <path
                            id="Path_31"
                            data-name="Path 31"
                            d="M251.342,248.7l-2.755,1.281,1.354-6.247Z"
                            transform="translate(-181.223 -159.815)"
                            fill={BrandColor}
                          />
                          <path
                            id="Path_32"
                            data-name="Path 32"
                            d="M266.83,229.915a7.8,7.8,0,0,1-.306,1.5c-1.48,4.78-10.488,17.6-10.488,17.6l-3.957-8.338-1.4-4.966,2.2-1.128a40.806,40.806,0,0,0,9.48-7.661,8.47,8.47,0,0,0,2.064-5.961S267.314,225.52,266.83,229.915Z"
                            transform="translate(-181.959 -151.794)"
                            fill={BrandColor}
                          />
                          <path
                            id="Path_33"
                            data-name="Path 33"
                            d="M246.75,249.979l-3.379-1.209,2.031-5.038Z"
                            transform="translate(-179.385 -159.815)"
                            fill={BrandColor}
                          />
                          <path
                            id="Path_34"
                            data-name="Path 34"
                            d="M250.645,208.661a.663.663,0,0,1,.4-.1.441.441,0,0,1,.28.545,1.141,1.141,0,0,1-.4.542,6.486,6.486,0,0,1-7.94.5c-.348-.227-.724-.63-.532-1a2.646,2.646,0,0,1,1.876.135A8.2,8.2,0,0,0,250.645,208.661Z"
                            transform="translate(-179.043 -147.425)"
                            fill="#313384"
                          />
                          <path
                            id="Path_35"
                            data-name="Path 35"
                            d="M191.04,372.862v12.171H154.273c-5.83,0-10.2-3.919-9.632-10.72A67.355,67.355,0,0,1,147.679,361a57.9,57.9,0,0,0,17.982,6.853c.473.1.965.2,1.464.291l.233.045Z"
                            transform="translate(-144.591 -201.122)"
                            fill="#e2a0b8"
                          />
                          <path
                            id="Path_36"
                            data-name="Path 36"
                            d="M328.662,380.6c-1.639,2.87-4.742,4.437-8.427,4.437H283.468V372.862l23.682-4.67.233-.045c.5-.091,2.034-.79,2.507-.894A51.047,51.047,0,0,0,326.829,361a67.353,67.353,0,0,1,3.038,13.311A10.731,10.731,0,0,1,328.662,380.6Z"
                            transform="translate(-193.509 -201.122)"
                            fill="#fbc"
                          />
                          <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M328.662,391.252c-1.639,2.87-4.742,4.437-8.427,4.437H283.468v-4.346C294.078,391.459,315.066,391.628,328.662,391.252Z"
                            transform="translate(-193.509 -211.777)"
                            fill="#e2a0b8"
                          />
                          <path
                            id="Path_38"
                            data-name="Path 38"
                            d="M243.371,251.471v28.693h6.134V251.4l-2.755,1.281Z"
                            transform="translate(-179.385 -162.515)"
                            fill={BrandColor}
                          />
                          <ellipse
                            id="Ellipse_1"
                            data-name="Ellipse 1"
                            cx="1.538"
                            cy="1.538"
                            rx="1.538"
                            ry="1.538"
                            transform="translate(65.653 94.654)"
                            fill="#fff"
                          />
                          <ellipse
                            id="Ellipse_2"
                            data-name="Ellipse 2"
                            cx="1.538"
                            cy="1.538"
                            rx="1.538"
                            ry="1.538"
                            transform="translate(65.653 106.961)"
                            fill="#fff"
                          />
                          <path
                            id="Path_39"
                            data-name="Path 39"
                            d="M209.134,189.426c-1.445.246-5.726-4.735-5.726-9.444s.732-7.074,2.066-7.514,3.66-.155,3.66,2.2c0,0,2.319,11.472,2.591,12.353S210.579,189.186,209.134,189.426Z"
                            transform="translate(-165.309 -134.652)"
                            fill="#fff"
                          />
                          <path
                            id="Path_40"
                            data-name="Path 40"
                            d="M283.274,187.821a2.582,2.582,0,0,1-.382-.052c-1.328-.253-2.6-1.283-2.423-2.105.194-.9,1.438-12.534,1.438-12.534-.22-2.345,2.066-2.844,3.433-2.533a1.521,1.521,0,0,1,.628.317c1.036.8,1.762,3.044,2.125,6.97C288.534,182.574,284.738,187.931,283.274,187.821Z"
                            transform="translate(-192.447 -134.024)"
                            fill="#fff"
                          />
                          <path
                            id="Path_41"
                            data-name="Path 41"
                            d="M209.079,158.645c0-7.309-1.239-9.183-1.239-14.271s4.122-19.983,26.453-19.091,24.97,13.6,25.334,18.421-.912,14.393-.912,14.393"
                            transform="translate(-166.87 -118.079)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_42"
                            data-name="Path 42"
                            d="M209.079,160.3c0-6.63-1.239-8.381-1.239-13s4.122-18.126,26.453-17.317,24.97,12.337,25.334,16.71-.912,13.056-.912,13.056"
                            transform="translate(-166.87 -119.739)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_43"
                            data-name="Path 43"
                            d="M284.6,188.038a2.58,2.58,0,0,1-.382-.052,132.741,132.741,0,0,0,3.077-16.855c1.036.8,1.762,3.044,2.125,6.97C289.86,182.792,286.064,188.149,284.6,188.038Z"
                            transform="translate(-193.773 -134.242)"
                            fill="#fff"
                          />
                          <path
                            id="Path_44"
                            data-name="Path 44"
                            d="M209.134,189.52c-1.445.246-5.726-4.735-5.726-9.444s.732-7.074,2.066-7.514C205.474,172.562,207.327,185.394,209.134,189.52Z"
                            transform="translate(-165.309 -134.746)"
                            fill="#fff"
                          />
                          <g
                            id="Group_9"
                            data-name="Group 9"
                            transform="translate(72.304 48.624)"
                          >
                            <path
                              id="Path_45"
                              data-name="Path 45"
                              d="M277.3,189.247l-.008.051a39.024,39.024,0,0,1-3.571,10.627,25.894,25.894,0,0,1-5.369,7.274,17.58,17.58,0,0,1-8.7,4.443l.245,1.272a18.935,18.935,0,0,0,9.565-4.981,30.855,30.855,0,0,0,7.341-11.884,38.148,38.148,0,0,0,1.776-6.612l-1.281-.191Z"
                              transform="translate(-257.427 -189.247)"
                              fill="#fff"
                            />
                            <path
                              id="Path_46"
                              data-name="Path 46"
                              d="M259.772,225.219l-1.567.222a1.747,1.747,0,1,1-.49-3.459l1.567-.222a1.747,1.747,0,0,1,.49,3.459Z"
                              transform="translate(-256.213 -200.693)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_68166"
                        data-name="Group 68166"
                        transform="translate(128.5 1184.728)"
                      >
                        <path
                          id="Path_47"
                          data-name="Path 47"
                          d="M269.242,362.252H176.05L172.357,311.9a5.092,5.092,0,0,1,5.078-5.464h90.422a5.092,5.092,0,0,1,5.078,5.464Z"
                          transform="translate(-172.344 -306.432)"
                          fill={BrandColor}
                        />
                        <path
                          id="Path_75045"
                          data-name="Path 75045"
                          d="M0,0H93.073A3.563,3.563,0,0,1,89.51,3.563H3.563A3.563,3.563,0,0,1,0,0Z"
                          transform="translate(3.707 55.82)"
                          fill={BrandColor}
                        />
                        <ellipse
                          id="Ellipse_3"
                          data-name="Ellipse 3"
                          cx="5.917"
                          cy="5.917"
                          rx="5.917"
                          ry="5.917"
                          transform="matrix(0.924, -0.383, 0.383, 0.924, 42.572, 24.708)"
                          fill="#d1e7ff"
                        />
                      </g>
                      <g
                        id="Support_Button_Group"
                        data-name="Support Button Group"
                        transform="translate(150 1300)"
                      >
                        <rect
                          x="-80"
                          y="-21"
                          width="207"
                          height="39"
                          rx="2"
                          ry="2"
                          fill={brandColor}
                        />
                        <text
                          id="Support_Button_Text"
                          x="19.5"
                          y="0"
                          font-family="Arial, sans-serif"
                          font-size="16px"
                          fill="#dce7e8"
                          text-anchor="middle"
                          alignment-baseline="middle"
                          letter-spacing="0.6"
                        >
                          Support
                        </text>
                        <g
                          id="Support_Button_Icon"
                          transform="translate(10 10)"
                        >
                          <circle cx="53" cy="-11" r="7" fill="#ffffff" />
                          <text
                            x="53.3"
                            y="-10.5"
                            font-family="Poppins, sans-serif"
                            font-size="13px"
                            fill={BrandColor}
                            text-anchor="middle"
                            alignment-baseline="middle"
                          >
                            ?
                          </text>
                        </g>
                      </g>
                    </g>
                  </svg>
                </p>
              </a>
            </li>
            <li className="nav-item">
              <NavLink to="/privacy-policy" className="nav-link">
                <p style={{ fontSize: "13px" }}>Privacy Policy</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/terms-and-conditions" className="nav-link">
                <p style={{ fontSize: "13px" }}>Terms & Conditions</p>
              </NavLink>
            </li>
            <li className="nav-item mt-4">
              <a
                href="#"
                className="nav-link"
                onClick={() => {
                  setWarningModal(true);
                  setWarningModalType("logout");
                  setWarningModalTitle("Logout Confirmation");
                  setWarningModalMessage("Are you sure you want to logout?");
                }}
              >
                <img
                  src={Logout}
                  className="nav-icon img-fluid"
                  alt="sidebar-icon"
                />
                <p>Log Out</p>
              </a>
            </li>
          </ul>
        </nav>
        <Modal
          show={isOpen}
          onHide={closeModal}
          className="help-modal"
          backdrop="static"
        >
          <Modal.Body>
            <div className="modal-header border-0 justify-content-center">
              <h5 className="modal-title mx-auto">HELP</h5>
              <button
                type="button"
                className="close ml-0 pl-0"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">
                  <img src={close} />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col col-lg-12">
                  <div className="form-group text-center">
                    <a
                      class="btn btn-custom"
                      style={{
                        backgroundColor: `${
                          brandColor !== null ? brandColor : ""
                        }`,
                        opacity: "0.7",
                      }}
                      href={`mailto:${partnerEmail}`}
                    >
                      Contact your instructor
                    </a>
                  </div>
                </div>
                <div className="col col-lg-12">
                  <div className="form-group text-center">
                    <a
                      class="btn btn-custom"
                      style={{
                        backgroundColor: `${
                          brandColor !== null ? brandColor : ""
                        }`,
                        opacity: "0.7",
                      }}
                      href="https://wisdome-members.eniston.com/"
                      target="_blank"
                    >
                      Visit help site
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
