import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { getRecentChallenges } from "../../helper/service";
import ChallengeEnrollBtn from "./ChallengeEnrollBtn";
import { Link } from "react-router-dom";
import placeholder from "../../images/placeholder.jpg";
import { Spinner } from "react-bootstrap";
import { challengeinsidelesson } from "../../helper/service";
function RecentChallenges({ searchChallenge }) {
  const [lessonCount, setLessonCount] = useState({});
  const getRecentChallengesQuery = useQuery({
    queryKey: ["getRecentChallenges"],
    queryFn: () => {
      return getRecentChallenges();
    },
  });

  const isLoading = getRecentChallengesQuery.isLoading;
  const challenges = useMemo(() => {
    return searchChallenge
      ? getRecentChallengesQuery.data?.result.filter((course) =>
          course?.title?.toLowerCase().includes(searchChallenge.toLowerCase())
        )
      : getRecentChallengesQuery.data?.result;
  }, [searchChallenge, getRecentChallengesQuery.data]);

  const calculateLessonCounts = async (challenges) => {
    const counts = {};
    for (const challenge of challenges) {
      const data = await challengeinsidelesson(challenge._id);
      const datas = {
        res: data.result,
      };
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      if (Array.isArray(datas.res)) {
        const filteredRes = datas.res.filter((item) => {
          const scheduleDate = item.schedule ? new Date(item.schedule) : null;
          return !scheduleDate || scheduleDate <= currentDate;
        });
        counts[challenge._id] = filteredRes.length;
      } else {
        counts[challenge._id] = 0;
      }
    }
    return counts;
  };

  useEffect(() => {
    if (Array.isArray(challenges)) {
      calculateLessonCounts(challenges).then((counts) => {
        setLessonCount(counts);
      });
    }
  }, [challenges]);

  if (!challenges?.length) {
    return (
      <p className="p-5">
        You have not enrolled in any challenge
        {searchChallenge && (
          <span>
            {" "}
            for <i className="text-danger">"{searchChallenge}"</i>
          </span>
        )}
      </p>
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <Swiper
      modules={[Navigation, Mousewheel]}
      navigation={true}
      spaceBetween={25}
      slidesPerView={1}
      breakpoints={{
        400: {
          width: 400,
          slidesPerView: 2,
        },
        768: {
          width: 768,
          slidesPerView: 3,
        },
      }}
    >
      {Array.isArray(challenges) &&
        challenges.map((challenge, index) => {
          if(lessonCount[challenge._id] > 0)
          { const lesson = lessonCount[challenge._id] > 1 ? "Lessons" : "Lesson";
            const Students = challenge.tUser > 1 ? "Students" : "Student";
            const imgs = challenge?.challengeImage || placeholder;
            const userId = localStorage.getItem("user_id");
            const isEnrolled = challenge?.students?.includes(userId);
            const isPaused = challenge?.enrollment?.toUpperCase() === "PAUSED";

            let challengeSchedule = challenge?.schedule;
            let challengeCreatedDate = "";
            let formattedDateTime = "";
            if (challengeSchedule) {
              var originalDate = new Date(challengeSchedule);
              var selectedTimeZone = localStorage.getItem("websiteTimeZone");
              if (selectedTimeZone) {
                challengeCreatedDate = originalDate.toLocaleString("en-US", {
                  timeZone: selectedTimeZone,
                });

                var currentDateTime = new Date();
                formattedDateTime = currentDateTime.toLocaleString("en-US", {
                  timeZone: selectedTimeZone,
                });
              }
            }
            if (challengeSchedule) {
              if ((challengeCreatedDate < formattedDateTime)|| (isEnrolled && isPaused)) {
                return (
                <SwiperSlide className="highlight-card">
                    <div className="slide-card__root">
                      <div className="slide-card__img_wrapper">
                        <img
                          src={imgs}
                          className="slide-card__img_wrapper__img"
                          alt="challenge"
                        />
                      </div>
                      {isEnrolled ? (
                        <h5 className="slide-card__title cursor-pointer">
                          <Link to={`/challenges-library/${challenge._id}`}>
                            {challenge.title}
                          </Link>
                        </h5>
                      ) : (
                        <a
                          className="slide-card__title cursor-pointer"
                          onClick={() =>
                            alert("Please enroll first to access challenge")
                          }
                        >
                          {challenge.title}
                        </a>
                      )}
                      <div>
                        <p className="slide-card__text">
                          {lessonCount[challenge._id]} {lesson}
                        </p>
                        <p className="slide-card__text">
                          {challenge.tUser} {Students}
                        </p>
                      </div>
                      <div className="slide-card__actions">
                        {isEnrolled && <span>Enrolled</span>}
                        {!isEnrolled && !isPaused && (
                          <ChallengeEnrollBtn challengeId={challenge._id} />
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              }
            } else {
              return (
              <SwiperSlide className="highlight-card">
                  <div className="slide-card__root">
                    <div className="slide-card__img_wrapper">
                      <img
                        src={imgs}
                        className="slide-card__img_wrapper__img"
                        alt="challenge"
                      />
                    </div>
                    {isEnrolled ? (
                      <h5 className="slide-card__title cursor-pointer">
                        <Link to={`/challenges-library/${challenge._id}`}>
                          {challenge.title}
                        </Link>
                      </h5>
                    ) : (
                      <a
                        className="slide-card__title cursor-pointer"
                        onClick={() =>
                          alert("Please enroll first to access challenge")
                        }
                      >
                        {challenge.title}
                      </a>
                    )}
                    <div>
                      <p className="slide-card__text">
                        {lessonCount[challenge._id]} {lesson}
                      </p>
                      <p className="slide-card__text">
                        {challenge.tUser} {Students}
                      </p>
                    </div>
                    <div className="slide-card__actions">
                      {isEnrolled && <span>Enrolled</span>}
                      {!isEnrolled && !isPaused && (
                        <ChallengeEnrollBtn challengeId={challenge._id} />
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
          }
        })}
    </Swiper>
  );
}

export default RecentChallenges;
