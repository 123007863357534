import React, { useState, useEffect } from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { userInfo } from "../../helper/service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Loader from '../Loader';
import { useNavigate } from "react-router-dom";

export default function Slider(props) {
  const [socialtier, setSocialtier] = useState("");
  const [Brandcolor, setBrandcolor] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const brandColor = localStorage.getItem("subadminBrandColor");
    if (brandColor) {
      setBrandcolor(brandColor);
    }
    const userId = localStorage.getItem("user_id");
    userInfo(userId).then((data) => {
      setSocialtier(data.result.validUser.subsType);
        setLoading(false); 
      
    });
  }, [props.postData]);
  const bindData = () => {
    if (props.postData?.length > 0) {
      return (
        <Swiper
          spaceBetween={10}
          slidesPerView={5}
          modules={[Navigation, Mousewheel]}
          navigation={true}
          breakpoints={{
            400: {
              width: 400,
              slidesPerView: 2,
            },
            768: {
              width: 768,
              slidesPerView: 3,
            },
          }}
        >
          {props.postData
            .sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt))
            .slice(0, 5)
            .map((res, index) => {
             // let memberCount=[res?.userId.find(u=>u.userid)]
              let userIdCheck = props?.postData[index]?.userId?.find(
                (i) => i?.userid == localStorage.getItem("user_id")
              );
              if (
                props.postData[index]?.memberAccessLevel?.includes(
                  socialtier
                ) ||
                userIdCheck
              ) {
                var posts = 0;
                if (res.posts) {
                  posts = res?.posts?.length;
                }
                return (
                  <SwiperSlide
                    key={Math.random()}
                    onClick={() => {
                      navigate(`/social-wall/wall-detail/${res._id}`);
                    }}
                  >
                    <div className="item">
                      <div className="main-img w-100">
                        <img
                          src={res?.image}
                          className="img-over w-100"
                          alt={res?.title}
                        />
                      </div>
                      <h5 className="slide-title pointer">{res?.title}</h5>

                      <p className="slide-text">
                        {posts} {posts <= 1 ? "Post" : "Posts"}
                      </p>
                      <p className="slide-text">
                        {res?.socialWallUsers?.length}{" "}
                        {res?.socialWallUsers?.length <= 1 ? "Member" : "Members"}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              } else {
                var posts = 0;
                if (res?.posts) {
                  posts = res?.posts?.length;
                }
                return (
                  <SwiperSlide
                    key={Math.random()}
                    onClick={() => {
                      navigate(`/social-wall/wall-detail/${res._id}`);
                    }}
                  >
                    <div
                      className="item"
                      style={{
                        border: `3px solid ${
                          hoveredIndex === index ? Brandcolor : "transparent"
                        }`,
                        "box-shadow": `8px 8px 10px ${
                          hoveredIndex === index ? Brandcolor : "transparent"
                        }`,
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div className="main-img w-100">
                        <img
                          src={res?.image}
                          className="img-over w-100"
                          alt={res?.title}
                        />
                      </div>
                      <h5 className="slide-title">{res?.title}</h5>

                      <p className="slide-text">
                        {posts} {posts <= 1 ? "Post" : "Posts"}
                      </p>
                      <p className="slide-text">
                        {res?.userId?.length}{" "}
                        {res?.userId?.length <= 1 ? "Member" : "Members"}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              }
            })}
        </Swiper>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-11">{loading ? (
          <Loader /> 
        ) : props.postData && props.postData.length > 0  ? (
          bindData() 
        ) : (
          "Currently, there are no social walls available for you." 
        )}</div>
    </div>
  );
}
