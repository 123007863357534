import React, { useState, useEffect } from "react";

import Header from "../common/Header";
import Slider from "./Slider";
import Loader from "../Loader";
import SocialwallGrid from "./SocialwallGrid";

import searchDark from "../../images/enduser/search-dark.svg";

import { PopularSocialList, socialWallUsers } from "../../helper/service";

export default function Socialwall() {
  const [isLoader, setIsLoader] = useState(false);
  const [walls, setWalls] = useState([]);
  const [originalWalls, setOriginalWalls] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    fetchPopularSocialWall();
  }, []);

  const fetchPopularSocialWall = async () => {
    try {
    const idd = localStorage.getItem("user_id");
    setIsLoader(true);
    var datas = await PopularSocialList(idd);

    for (const item of datas.result) {
      const selectedUsers = item.userId.map((user) => user.userid);
      const socialWallUsersAndMemAccessLevel = {
        users: selectedUsers,
        memberAccessLevel: item.memberAccessLevel,
      };
  
      const res = await socialWallUsers(socialWallUsersAndMemAccessLevel);
      if (res?.data.result && res?.data.result.length > 0) {
        let users = res.data.result[0];
        if (Array.isArray(users)) {
          item.socialWallUsers = res.data.result[0];
        } else {
          item.socialWallUsers = res.data.result;
        }
      }
    }
    setWalls(datas.result);
    setOriginalWalls(datas.result);
  } catch (error) {
    console.error("Error fetching walls:", error);
  } finally {
    setIsLoader(false); 
  }
  };

  const searchData = (event) => {
    event.preventDefault();
    const { value } = event.target;

    if (value !== "") {
      let filteredWalls = originalWalls.filter((ele) =>
        ele.title.toLowerCase().includes(value.toLowerCase())
      );

      setWalls(filteredWalls);
    } else {
      setWalls(originalWalls);
    }
  };

  return (
    <div className="wrapper">
      <Header />
      {isLoader ? (
        <Loader />
      ) : (
        <div className="content-wrapper enduser-social-wall">
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-8 col-lg-6 col-md-7">
                  <h2 className="title">SOCIAL WALL</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ul
                    className="nav nav-tabs"
                    id="socialWallTab"
                    role="tablist"
                  >
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => setShowSearchBar(false)}
                    >
                      <a
                        className="nav-link active"
                        id="recentlyAdded-tab"
                        data-toggle="tab"
                        href="#recentlyAdded"
                        role="tab"
                        aria-controls="recentlyAdded"
                        aria-selected="true"
                      >
                        Recently Added
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => setShowSearchBar(true)}
                    >
                      <a
                        className="nav-link"
                        id="viewAll-tab"
                        data-toggle="tab"
                        href="#viewAll"
                        role="tab"
                        aria-controls="viewall"
                        aria-selected="false"
                      >
                        View All
                      </a>
                    </li>
                    <li className="ml-auto">
                      {showSearchBar && (
                        <div className="search-library">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search "
                            name="search"
                            onChange={searchData}
                          />
                          <span className="search-icon">
                            <img src={searchDark} />
                          </span>
                        </div>
                      )}
                    </li>
                  </ul>
                  <div className="tab-content" id="socialWallTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="recentlyAdded"
                      role="tabpanel"
                      aria-labelledby="recentlyAdded-tab"
                    >
                      <Slider postData={walls} />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="viewAll"
                      role="tabpanel"
                      aria-labelledby="viewAll-tab"
                    >
                      <SocialwallGrid postData={walls} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
