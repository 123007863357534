import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getEnrolledCourses } from "../../helper/service";
import { Spinner } from "react-bootstrap";
import {
  IgetRecentCoursesResponse,
  IResponseError,
} from "../../interfaces/interfaces";
import { unenrollCourse } from "../../helper/service";

import { Link } from "react-router-dom";

import SuccessPopup from "../Setting/SuccessPopup";
import { Button } from "react-bootstrap";

import { Navigation, Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { AxiosError } from "axios";

type EnrolledCoursesFoldersListProps = {
  searchQuery?: string;
};

let brandColor = localStorage.getItem("subadminBrandColor");

function EnrolledCoursesFoldersList(props: EnrolledCoursesFoldersListProps) {
  const [BrandColor, setBrandColor] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [unenrollingCourseId, setUnenrollingCourseId] = useState<string | null>(null);

  useEffect(() => {
    const brandColor = localStorage.getItem("subadminBrandColor");
    if (brandColor) {
      setBrandColor(brandColor);
    }
  }, []);

  const getEnrolledCoursesQuery = useQuery<
    IgetRecentCoursesResponse,
    AxiosError<IResponseError>
  >({
    queryKey: ["getEnrolledCourses"],
    queryFn: () => {
      return getEnrolledCourses();
    },
  });

  const queryClient = useQueryClient();
  const enrollCourseMutation = useMutation({
    mutationKey: ["unenrollCourse"],
    mutationFn: (id) => {
      return unenrollCourse(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["getEnrolledCourses"] });
      await queryClient.invalidateQueries({ queryKey: ["getAllCourses"] });
      setUnenrollingCourseId(null);
    },
    onError: (error) => {
      console.log(
        "🚀 ~ file: EnrolledCoursesFolderList.tsx:63 ~ onError ~ error",
        error
      );
      setUnenrollingCourseId(null); 
      alert("Something went wrong! Failed to unenroll course");
    },
  });

  const handleUnenroll = (id) => {
    const confirm = window.confirm("Are you sure you want to unenroll?");
    if (!confirm) return;
    setUnenrollingCourseId(id); 
    enrollCourseMutation.mutate(id);
  };

  // const [searchQuery] = useSearchQuery();
  const filteredCourses = useCallback(() => {
    return getEnrolledCoursesQuery.data?.result?.filter((course) => {
      if (!props.searchQuery) return true;
      return course?.title
        ?.toLowerCase()
        .includes(props.searchQuery.toLowerCase());
    });
  }, [getEnrolledCoursesQuery.data?.result, props.searchQuery]);

  if (getEnrolledCoursesQuery.isLoading) {
    return <Spinner animation="border" />;
  }
  console.log(
    "🚀 ~ file: EnrolledCoursesFoldersList.tsx:37 ~ EnrolledCoursesFoldersList ~ getEnrolledCoursesQuery:",
    getEnrolledCoursesQuery
  );

  if (
    getEnrolledCoursesQuery.isError ||
    !getEnrolledCoursesQuery.data?.result ||
    !Array.isArray(getEnrolledCoursesQuery.data?.result)
  ) {
    if (getEnrolledCoursesQuery.error?.response?.data?.message) {
      return (
        <p className="p-5">
          {getEnrolledCoursesQuery.error?.response?.data?.message}
        </p>
      );
    }
    return !getEnrolledCoursesQuery.data ||
      getEnrolledCoursesQuery.data.status !== 200 ? (
      <p className="text-danger p-5">
        Something went wrong! Failed to load Courses
      </p>
    ) : (
      <></>
    );
  }

  if (filteredCourses()?.length === 0) {
    return (
      <p className="p-5">
        You have not enrolled in any course yet
        {props.searchQuery && (
          <span>
            {" "}
            for <i className="text-danger">"{props.searchQuery}"</i>
          </span>
        )}
      </p>
    );
  }

  // const allCoursesFolders = filteredCourses()?.map((course) => {
  //   return (
  //     <SwiperSlide
  //       key={course._id}
  //       className="className='discover-slider courses-slider owl-carousel owl-theme'"
  //     >
  //       <CourseCard course={course} />
  //     </SwiperSlide>
  //   );
  // });

  return (
    <Swiper
      modules={[Navigation, Mousewheel, Pagination]}
      navigation={true}
      spaceBetween={25}
      pagination={true}
      slidesPerView={1}
      breakpoints={{
        400: {
          width: 400,
          slidesPerView: 2,
        },
        768: {
          width: 768,
          slidesPerView: 3,
        },
      }}
    >
      {filteredCourses()?.map((course, index) => {
        const numberOfStudents = course?.students?.length || 0;
        let userId = localStorage.getItem("user_id");
        let studentEnroll = course?.students?.includes(`${userId}`);
        let courseLesson = course.tLesson;
        let courseSchedule = course?.schedule;
        let courseScheduleDate = "";
        let formattedCurrentDateTime = "";
        if (courseSchedule) {
          var originalDate = new Date(courseSchedule);
          var selectedTimeZone = localStorage.getItem("websiteTimeZone");
          if (selectedTimeZone) {
            // Change this to your desired timezone
            courseScheduleDate = originalDate.toLocaleString("en-US", {
              timeZone: selectedTimeZone,
            });

            var currentDateTime = new Date();
            formattedCurrentDateTime = currentDateTime.toLocaleString("en-US", {
              timeZone: selectedTimeZone,
            });
          }
        }

        if (studentEnroll) {
          if (courseSchedule) {
            if (
              new Date(courseScheduleDate) < new Date(formattedCurrentDateTime)
            ) {
              return (
                <SwiperSlide
                  key={course._id}
                  className="discover-slider courses-slider owl-carousel owl-theme highlight-card"
                >
                  <div>
                    <Link
                      to={{
                        pathname: `/course-module/${course._id}`,
                      }}
                      state={course}
                    >
                      <div className="main-img w-100">
                        <img
                          src={course.courseImage}
                          className="img-over w-100"
                          alt={course.title}
                        />
                      </div>
                      <h5 className="slide-title">{course.title}</h5>
                    </Link>

                    <p className="slide-text">
                      <span>
                        {courseLesson && courseLesson}{" "}
                        {courseLesson && courseLesson > 1 ? "Lessons" : "Lesson"}
                      </span>
                    </p>
                    <p className="slide-text float-left">
                      <span>
                        {numberOfStudents && numberOfStudents}{" "}
                        {numberOfStudents && numberOfStudents > 1 ? "Students" : "Student"}
                      </span>
                    </p>
                    {!course?.acceptIndPayment && (
                      <div className="text-center cursor-pointer">
                        {enrollCourseMutation.isPending && unenrollingCourseId === course._id ? (
                          <span>Unenrolling...</span>
                        ) : (
                          <Button
                            className="purchase-button"
                            style={{
                              backgroundColor: `${
                                brandColor !== null ? brandColor : ""
                              }`,
                              minWidth: "150px",
                            }}
                            onClick={() => {
                              handleUnenroll(course._id);
                            }}
                          >
                            <span>Unenroll</span>
                          </Button>
                        )}
                      </div>
                    )}

                    <div className="clearfix"></div>
                  </div>
                </SwiperSlide>
              );
            }
          } else {
            return (
              <SwiperSlide
                key={course._id}
                className="discover-slider courses-slider owl-carousel owl-theme highlight-card rounded-lg"
              >
                <div>
                  {/* <Link to={`courses-details/${course._id}`}> */}
                  <Link
                    to={{
                      pathname: `/course-module/${course._id}`,
                    }}
                    state={course}
                  >
                    <div className="main-img w-100">
                      <img
                        src={course.courseImage}
                        className="img-over w-100"
                        alt={course.title}
                      />
                    </div>
                    <h5 className="slide-title">{course.title}</h5>
                  </Link>

                  <p className="slide-text">
                    <span>
                      {course.tLesson}{" "}
                      {typeof course?.tLesson === "number" &&
                      course?.tLesson > 1
                        ? "Lessons"
                        : "Lesson"}
                    </span>
                  </p>
                  <p className="slide-text float-left">
                    <span>
                      {numberOfStudents}{" "}
                      {numberOfStudents > 1 ? "Students" : "Student"}
                    </span>
                  </p>
                  {!course?.acceptIndPayment && (
                    <div className="text-center cursor-pointer">
                        {enrollCourseMutation.isPending && unenrollingCourseId === course._id ? (
                        <span>Unenrolling...</span>
                      ) : (
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: `${
                              brandColor !== null ? brandColor : ""
                            }`,
                            minWidth: "150px",
                          }}
                          onClick={() => {
                            handleUnenroll(course._id);
                          }}
                        >
                          <span>Unenroll</span>
                        </Button>
                      )}
                    </div>
                  )}

                  <div className="clearfix"></div>
                </div>
              </SwiperSlide>
            );
          }
        }
      })}
    </Swiper>
  );
}

export default EnrolledCoursesFoldersList;
